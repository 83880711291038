<template>
  <div class="quote-detail h-font-lg">
    <div v-if="formData" ref="html2canvas" class="quote-detail_content" :style="`background: ${ !pageData.totalShow ? `url(${bgTitlePng}) no-repeat fixed;` : `linear-gradient(to top, #fff, #3bb1a7)` }`">
      <div class="quote-detail_head h-flex h-row-between">
        <div>
          <span>{{formData.basis.insuredOrgName}}</span><span>投保方案</span>
          <van-icon v-if="['3', '2'].includes(quote.basis.insuredStatus)" @click="refreshStatus" class="refresh" :name="require('./img/refresh.png')" />
        </div>
        <div v-if="!pageData.totalShow" @click="share()">
          <van-icon :name="require('@/assets/images/share.png')" />
          <div style="font-size:10px">分享</div>
        </div>
      </div>
      <div class="base h-group">
          <div class="h-cell h-cell--center h-min-height">
            <div class="h-cell__title" style="font-size:20px">{{formData.vehicle.plateNo || '新车未上牌'}}</div>
            <div v-show="!pageData.totalShow" class="h-cell__value">
              <van-button v-if="!['7'].includes(quote.basis.insuredStatus)" type="primary" icon="edit" size="mini" @click="toPath">调整方案</van-button>
            </div>
          </div>
            <div class="h-cell__content">
              <div class="warn">
                <i class="van-icon van-icon-warning" /> {{riskDesc}}
              </div>
          </div>
      </div>
      <div>
          <div v-if="JSON.stringify(formData.compulsory) !== '{}'" class="compulsory h-group">
              <div class="h-cell h-cell--center">
                <div class="h-cell__title">
                    <van-col span="8">
                      <span class="icon" style="background-color:#57ccc2">强</span>交强险
                    </van-col>
                    <van-col span="16">
                      <span v-if="formData.compulsory">{{ formData.compulsory.policyCode }}</span>
                    </van-col>
                </div>
              </div>
              <div class="h-cell__content">
                <van-row>
                  <van-col span="8"  class="h-text-color_gray-6">保险期间</van-col>
                  <van-col span="16">{{dayjs(formData.compulsory.startTime).format('YYYY-MM-DD HH:mm')}} 至 {{dayjs(formData.compulsory.endTime).format('YYYY-MM-DD HH:mm')}}</van-col>
                </van-row>
                <van-row>
                  <van-col span="8" class="h-text-color_gray-6">交强险</van-col>
                  <van-col span="8">投保</van-col>
                  <van-col span="8" class="h-text-right h-price-color">￥<span class="h-font-md">{{formData.compulsory.actualPremium}}</span></van-col>
                </van-row>
                <van-row v-if="formData.compulsory.vehicleTaxPremium">
                  <van-col span="8" class="h-text-color_gray-6">车船税</van-col>
                  <van-col span="8">纳税</van-col>
                  <van-col span="8" class="h-text-right h-price-color">￥<span class="h-font-md">{{formData.compulsory.vehicleTaxPremium}}</span></van-col>
                </van-row>
              </div>
          </div>

          <div v-if="JSON.stringify(formData.commercial) !== '{}'" class="commercial h-group">
            <div class="h-cell h-cell--center">
              <div class="h-cell__title">
                  <van-col span="8">
                    <span class="icon" style="background-color:#ff976a">商</span>商业险
                  </van-col>
                  <van-col span="16">
                    <span v-if="formData.commercial">{{ formData.commercial.policyCode }}</span>
                  </van-col>
              </div>
            </div>
              <div class="h-cell__content">
                <van-row>
                  <van-col span="8"  class="h-text-color_gray-6">保险期间</van-col>
                  <van-col span="16">{{dayjs(formData.commercial.startTime).format('YYYY-MM-DD HH:mm')}} 至 {{dayjs(formData.commercial.endTime).format('YYYY-MM-DD HH:mm')}}</van-col>
                </van-row>
                <div v-if="riskList&&riskList.length>0">
                  <template v-for="(item,index) in riskList">
                    <Commercial :key="index" :riskItem="item" :formData="formData"/>
                  </template>
                </div>
            </div>
        </div>
        <div v-if="serviceList&&serviceList.length>0" class="service h-group">
          <div class="h-cell h-cell--center">
              <div class="h-cell__title">
                <span class="icon" style="background-color:#07c160">增</span>特约条款
              </div>
          </div>
          <div class="h-cell__content">
              <van-row v-for="(item,index) in serviceList" :key="index">
                <van-col span="16" class="h-text-color_gray-6">{{item.riskName}}</van-col>
                <van-col span="8">
                  <span v-if="item.serviceList&&item.serviceList.length>1">
                    <ClauseView :list="item.serviceList" />
                  </span>
                  <span v-else>{{item.quantity}}次</span>
                </van-col>
              </van-row>
          </div>
        </div>
        <TermManage ref="termManage" :formData="formData" />
        <div class="compulsory h-group" v-show="pageData.totalShow">
          <div class="total">总保费：<span>￥{{totalPrem}}元</span></div>
          <div class="detail">
            <span v-if="commercialPrem">商业险:{{commercialPrem}}元</span>
            <span v-if="compulsoryPrem">交强险:{{compulsoryPrem}}元</span>
            <span v-if="compulsoryTaxPrem">车船税:{{compulsoryTaxPrem}}元</span>
          </div>
        </div>
      </div>
    </div>
    <div class="quote-result_bnt">
      <div class="quote-result_bnt--fixed" >
        <div class="success-btn success" v-if="quote.basis.insuredOrgCode === 'YGBX'">
          <van-button @click="underwriting" v-if="['0'].includes(quote.basis.insuredStatus)" type="primary" size="small">核&nbsp;&nbsp;保</van-button>
          <van-button @click="collection" v-if="['0', '2'].includes(quote.basis.insuredStatus) && (quote.holder.custType === '0' || quote.insured.custType === '0')" type="primary" size="small">身份证采集</van-button>
          <!-- 目前只处理了阳光，阳光是核保后才可上传资料 -->
          <van-button @click="toPage('UploadFile')" v-if="!['0', '7', '1'].includes(quote.basis.insuredStatus)" type="primary" size="small">上传资料</van-button>
          <van-button @click="toPage('UpdateCode')" v-if="permBtns.includes('C607') && ['2'].includes(quote.basis.insuredStatus) && (quote.holder.custType === '0' || quote.insured.custType === '0')" type="primary" size="small">验证码更新</van-button>
          <van-button @click="toPage('SendCode')" v-if="permBtns.includes('C606') && ['2'].includes(quote.basis.insuredStatus) && (quote.holder.custType === '0' || quote.insured.custType === '0')" type="primary" size="small">发送验证码</van-button>
          <van-button @click="toPage('Pay')" v-if="['2'].includes(quote.basis.insuredStatus)" type="primary" size="small">支&nbsp;&nbsp;付</van-button>
          <!-- <van-button @click="download" v-if="['7'].includes(quote.basis.insuredStatus)" type="primary" size="small">电子保单下载</van-button> -->
        </div>
        <div class="total">总保费：<span>￥{{totalPrem}}元</span></div>
        <div class="detail">
          <span v-if="commercialPrem">商业险:{{commercialPrem}}元</span>
          <span v-if="compulsoryPrem">交强险:{{compulsoryPrem}}元</span>
          <span v-if="compulsoryTaxPrem">车船税:{{compulsoryTaxPrem}}元</span>
        </div>
      </div>
    </div>
    <van-overlay :show="pageData.show" @click="pageData.show = false">
      <div class="wrapper">
        <img :src="imageUrl" style="max-height:80%" />
      </div>
  </van-overlay>
</div>
</template>
<script>
import bgTitlePng from "@/views/miniprogram/quote/result/image/bg-title.png"
import domtoimage from './dom-to-image'
import dayjs from 'dayjs'
import { request } from "@/api/service";
import { Toast, Notify, Dialog } from "vant";
import { mapState } from "vuex";
import { cloneDeep, isNaN } from "lodash";
import Common from '@/api/common'
import common from '../common/mixin/common.js'
import Commercial from '../components/riskView/Commercial'
import ClauseView from '../components/riskView/ClauseView'
import TermManage from './termManage'
export default {
  name: "QuoteDetail",
  components: {Commercial,ClauseView,TermManage},
  mixins: [common],
  provide() {
    return {
      pageContext: this
    };
  },
  data() {
    return {
      bgTitlePng,
      pageData:{
        show:false,
        totalShow: false
      },
      quote: {
        basis: {},
        commercial: {}
      },
      formData: {
        basis: {},
        commercial: {},
        vehicle: {},
        compulsory: {}
      },
      imageUrl:null,
      serviceRisks:["COMM0F01","COMM0G01","COMM0H01","COMM0J01"],
      id: null,
      permBtns: [],
      scrollY: 0,
      flag: false,
      amountData: ['COMM0A01', 'COMM1A01', 'COMM0A02', 'COMM1A02', 'COMM0A03', 'COMM1A03', 'COMM0B01', 'COMM1B01', 'COMM0B02', 'COMM1B02', 'COMM0B03', 'COMM1B03', 'COMM0C01', 'COMM1C01', 'COMM0C03', 'COMM1C03', 'COMM0D02', 'COMM1D02', 'COMM0B05', 'COMM1B05', 'COMM0C02', 'COMM1C02', 'COMM0M01', 'COMM1M01', 'COMM0M03', 'COMM1M03'],
      unitData: ['COMM0D01', 'COMM1D01', 'COMM0D03', 'COMM1D03', 'COMM0E01', 'COMM1E01'],
      productTypeCode: 'COMM',
      commTermData: [],
      compTermData: [],
      termData: [],
      addTermShow: false,
    };
  },
  computed: {
    ...mapState("car", ["quoteRes"]),
    ...mapState("user", ["info"]),
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial && this.quote.commercial.riskList){
        commercialFlag = true
        const commercialList= this.quote.commercial.riskList && this.quote.commercial.riskList.map(item=>item.riskName)
        commercialNum = commercialList && commercialList.length
        commercialStr=`商业险(${commercialList && commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项`
    },
    totalPrem(){
      let total=0
      if(this.formData.commercial && this.formData.commercial.actualPremium){
        total+=this.formData.commercial.actualPremium
      }
      if(this.formData.compulsory && this.formData.compulsory.actualPremium){
        total+=(this.formData.compulsory.actualPremium+(this.formData.compulsory.vehicleTaxPremium || 0))
      }
      if (this.flag) {
        return total.toFixed(2)
      }
      return ""
    },
    commercialPrem(){
      if(this.formData.commercial){
        return this.formData.commercial.actualPremium
      }
      return null
    },
    compulsoryPrem(){
      if(this.formData.compulsory){
        return this.formData.compulsory.actualPremium
      }
      return null
    },
    compulsoryTaxPrem(){
      if(this.formData.compulsory && this.formData.compulsory.vehicleTaxPremium){
        return this.formData.compulsory.vehicleTaxPremium
      }
      return null
    },
    riskList(){
      if(!(this.formData.commercial && this.formData.commercial.riskList)){
        return []
      }
      return this.formData.commercial.riskList.filter(item =>{
        return this.serviceRisks.indexOf(item.riskCode) === -1
      })
    },
    serviceList(){
      if(!(this.formData.commercial && this.formData.commercial.riskList)){
        return []
      }
      return this.formData.commercial.riskList.filter(item =>{
        return this.serviceRisks.indexOf(item.riskCode) > -1
      })
    }
  },
  async created() {
    const {id}=this.$route.query
    this.id = id
    if(!id){
      return false
    }
    await this.getDetail(id, 'detail')
  },
  methods: {
    dayjs,
    share () {
      this.saveImage('html2canvas', '报价单')
    },
    // 图片格式转换方法
    dataURLToBlob (dataurl) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    saveImage (divText, imgText) {
      // window.pageYoffset = 0;
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
      const canvasID = this.$refs[divText]
      const a = document.createElement('a')
      this.pageData.totalShow = true
      Toast.loading({
        overlay: true,
        message: "图片保存中..."
      });
      domtoimage.toJpeg(canvasID, { bgcolor: '#3bb1a7', quality: 1 })
        .then(dataUrl => {
          // const blob = this.dataURLToBlob(dataUrl)
          // a.setAttribute('href', URL.createObjectURL(blob))
          // // 这块是保存图片操作  可以设置保存的图片的信息
          // a.setAttribute('download', imgText + '.jpeg')
          // document.body.appendChild(a)
          // a.click()
          // URL.revokeObjectURL(blob)
          // document.body.removeChild(a)
          Toast.clear();
          this.pageData.show =true
          this.imageUrl = dataUrl
          this.pageData.totalShow = false
        })
        .catch(error => {
          Toast.clear();
          this.pageData.totalShow = false
        })
    },
    toPath(){
      this.$router.push({ 
        path: "/miniprogram/quote/normal/base",
        query: {
          id: this.$route.query.id
        }
      });
    },
    // 刷新状态
    refreshStatus () {
        let setInter = setInterval(() => {
          Toast.loading({
          forbidClick: true
          }, 500)
        })
        request({
          url: '/afis-api-manage/invoke/core/auto/updateAutoPolicyStatusIn',
          method: 'post',
          data: {
            subBusinessNo: this.id
          }
        }).then(res => {
            Notify({ type: 'success', message: '刷新成功' });
            clearInterval(setInter)
            Toast.clear()
            this.getDetail(this.id, 'detail')
        }).catch(e => {
            clearInterval(setInter)
            Toast.clear()
        })
    },
    // 核保
    underwriting () {
      let setInter = setInterval(() => {
        Toast.loading({
          forbidClick: true
        }, 500)
      })
      request({
        url: '/afis-api-manage/invoke/core/auto/underwritingIn',
        method: 'post',
        data: {
          subBusinessNo: this.id
        }
      }).then(res => {
          let info = this.quoteRes[res.basis.insuredOrgCode]
          for (let i in res) {
              info[i] = res[i]
          }
          this.$set(this.quoteRes, res.basis.insuredOrgCode, info)
          this.$store.dispatch("car/setQuoteRes", this.quoteRes)
          clearInterval(setInter)
          Toast.clear()
          if (!res.resultMsgsStr) {
            this.$router.push({
              name: 'Underwriting',
              query: {
                id: res.basis.subBusinessNo
              }
            })
          }
      }).catch(e => {
          clearInterval(setInter)
          Toast.clear()
      })
    },
    // 身份证采集
    collection () {
      this.$store.dispatch("car/setHolderInfo", this.quote.holder)
      this.$store.dispatch("car/setInsuredInfo", this.quote.insured)
      this.$router.push({
        name: 'CardCollection'
      })
    },
    toPage (name) {
      this.$router.push({
        name: name,
        query: {
          id: this.id
        }
      })
    },
    // 删除
    del () {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除吗',
      })
      .then(() => {
        request({
            url: `/afis-auto-web/auto/policy/delete/${this.id}`,
            method: "post",
            data: {}
        }).then(res => {
            Toast.success('删除成功')
            this.$router.go(-1)
        })
      }).catch(() => {
          // on cancel
      });
    },
    // 初始化按钮
    async initBtn(insuredOrgCode) {
      const { info } = this.$store.getters
      let dealerId = null
      let orgCode = null
      if (info.busiType === '2') {
        dealerId = info.orgId
        orgCode = info.issueOrgId
      }
      if (info.busiType === '1') {
        dealerId = this.quote.basis.dealerId
        orgCode = this.quote.basis.orgId
      }
      const permBtns = await request({
        url: '/afis-auto-web/auto/config/channel/queryQuoteBtnList',
        method: 'post',
        data: {
          commonApiConfigType: '1',
          orgCode,
          insuredOrgCode,
          businessChannelId: dealerId
        }
      })
      this.permBtns = permBtns || []
    },
    download () {
      request({
        url: '/afis-api-manage/invoke/core/auto/downPolicyElectronicIn',
        method: 'post',
        data: {
          basis: { subBusinessNo: this.id }
        }
      }).then(res => {
        if (res) {
          const { compulsory, commercial } = res
          compulsory && Common.urlDownload(compulsory.electronicUrl, {}, compulsory.productTypeName + compulsory.policyCode + '.pdf')
          commercial && Common.urlDownload(commercial.electronicUrl, {}, commercial.productTypeName + commercial.policyCode + '.pdf')
        }
      })
    },
    addTerm () {
        this.addTermShow = true
        this.getList()
    },
    getList () {
        request({
            url: '/afis-auto-web/config/autoCfgSpecialTerm/list',
            method: 'post',
            data: {
                page: 1,
                pageSize: 1000,
                productTypeCode: this.productTypeCode,
                orgId: this.info.orgId,
                insuredOrgCode: this.quote.basis.insuredOrgCode
            }
      }).then(res => {
          res.list.map(item => {
              this.$set(item, 'isEdit', false)
              this.$set(item, 'checked', false)
              this.$set(item, 'isDel', true)
          })
          this.termData = res.list
      })
    },
    edit (ind) {
        if (this.productTypeCode === 'COMP') {
            this.$set(this.resData.compulsory.specialTermList[ind], 'isEdit', !this.resData.compulsory.specialTermList[ind].isEdit)
        } else {
            this.$set(this.resData.commercial.specialTermList[ind], 'isEdit', !this.resData.commercial.specialTermList[ind].isEdit)
        }
    },
    termBlur (ind) {
        if (this.productTypeCode === 'COMP') {
            this.$set(this.resData.compulsory.specialTermList[ind], 'isEdit', false)
        } else {
            this.$set(this.resData.commercial.specialTermList[ind], 'isEdit', false)
        }
    },
    confirm () {
        let selectedData = this.termData.filter(item => item.checked)
        if (!selectedData.length) {
            Toast("请选择数据")
            return false
        }
        if (this.productTypeCode === 'COMP') {
            this.resData.compulsory.specialTermList = this.arrayUnique([...this.resData.compulsory.specialTermList, ...selectedData], 'id')
        } else {
            this.resData.commercial.specialTermList = this.arrayUnique([...this.resData.commercial.specialTermList, ...selectedData], 'id')
        }
        Toast.success("添加成功")
        this.addTermShow = false
    },
    arrayUnique (arr, name) {
        var hash = {};
        return arr.reduce(function (item, next) {
            hash[next[name]] ? '' : hash[next[name]] = true && item.push(next);
            return item;
        }, []);
    },
    delTerm (ind) {
        Dialog.confirm({
            title: '提示',
            message: '确定要删除吗',
        }).then(res => {
            if (this.productTypeCode === 'COMP') {
                this.resData.compulsory.specialTermList.splice(ind, 1)
            } else {
                this.resData.commercial.specialTermList.splice(ind, 1)
            }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
}
.h-cell__content{
  padding: 6px 8px;
  font-size: $font-size-sm;
  ::v-deep .van-row {
    padding:9px 0;
  }
}
.h-cell__title{
  font-weight: $font-weight-bold-lg;
  font-size: $font-size-md;
  span.icon {
    border-radius: $border-radius-sm;
    color: $white;
    padding: 3px;
    margin-right:6px;
  }
}
.h-cell{
  padding: 10px 8px;
}
.base{
  .warn{
    text-indent: -4px;
    padding-left: 12px;
    font-size: $font-size-xs;
    color: $primary-color;
  }
}
.quote-detail{
    background-color: $background-color;
    &_head{
      padding: 10px 2px;
      color:$white;
      font-size: $font-size-xl;
      min-height: 37px;
    }
    &_content{
      padding: 10px;
      background-size: 100% 260px;
      padding-bottom: 40px;
    }
    &_foot{
      background-color: $white;
      .total{
        font-size: $font-size-md;
        font-weight: $font-weight-bold-lg;
        padding: 5px;
        span{
          font-size: $font-size-xl;
          color: $red;
        }
      }
      .detail{
        font-size: $font-size-sm;
        color: $gray-5;
        span{
          padding:0 5px;
          border-right: $border-color solid $border-width-xs;
          &:last-child{
            border-right-width:0;
          }
        }
      }
    }
    .h-group{
      margin-bottom: 10px;
      padding: 8px;
      background-color: $white;
      border-radius: $border-radius-lg;
      box-shadow: $box-shadow-base;
      min-height: 105px;
      .h-min-height {
        min-height: 49px;
      }
    }
    .quote-result_bnt {
        height: 60px;
        .success {
            text-align: center;
        }
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
        button {
            display: inline-block;
            min-width: 40px;
            margin-right: 6px;
            height: 32px;
        }
        .total{
        font-size: $font-size-md;
        font-weight: $font-weight-bold-lg;
        padding: 5px;
        span{
          font-size: $font-size-xl;
          color: $red;
        }
      }
      .detail{
        font-size: $font-size-sm;
        color: $gray-5;
        span{
          padding:0 5px;
          border-right: $border-color solid $border-width-xs;
          &:last-child{
            border-right-width:0;
          }
        }
      }
    }
    .refresh {
      vertical-align: bottom;
      margin-left: 4px;
      display: inline-block;
    }
  }
  .quote-detail_content {
    .total{
      font-size: $font-size-md;
      font-weight: $font-weight-bold-lg;
      padding: 5px;
      span{
        font-size: $font-size-xl;
        color: $red;
      }
    }
    .detail{
        font-size: $font-size-sm;
        color: $gray-5;
        span{
          padding:0 5px;
          border-right: $border-color solid $border-width-xs;
          &:last-child{
            border-right-width:0;
          }
        }
      }
  }
::v-deep .van-tabs__nav--card {
    margin: 0;
}
.term_content {
    position: relative;
    top: -20px;
    .term_tit {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .term_text {
        padding-left: 10px;
        color: rgb(121, 121, 121);
        margin-top: 6px;
        font-size: 14px;
    }
    .van-field {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
}
.term_content_list {
    padding: 0 10px 10px 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    .list {
        height: 300px;
        overflow-y: auto;
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .list_title {
        font-size: 18px;
    }
    .term_tit {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .term_text {
        padding-left: 10px;
        color: rgb(121, 121, 121);
        margin-top: 6px;
        font-size: 14px;
    }
    .van-field {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .btn-bottom {
        text-align: center;
        margin-top: 10px;
        button {
            width: 40%;
            display: inline-block;
        }
    }
}
::v-deep .empy-img img {
    width: 100px;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50%;
    position: relative;
    left: 50px;
}
::v-deep .van-tabs-cla {
    width: 50%;
}
</style>
